import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { sportticketsApi } from 'src/app/shared/constants/api.constants';
import { IMatchTicket } from 'src/app/shared/models/match-ticket.model';
import { DataService } from 'src/app/shared/services/data.service';
import { HelpersService } from 'src/app/shared/services/helpers.service';

@Component({
  selector: 'app-choose-your-team-dialog',
  templateUrl: './choose-your-team-dialog.component.html',
  styleUrls: ['./choose-your-team-dialog.component.scss'],
})
export class ChooseYourTeamDialogComponent implements OnInit {
  teamSelectForm: FormGroup;
  isMobile: boolean = false;
  teamHomeTickets: any[] = [];
  teamAwayTickets: any[] = [];
  tickets: IMatchTicket[];
  constructor(
    private fb: FormBuilder,
    private spinner: NgxSpinnerService,
    public helpers: HelpersService,
    public translate: TranslateService,
    private dataService: DataService,
    private modalService: NgbModal
  ) {
    if (window.screen.width <= 768) {
      this.isMobile = true;
    }
  }

  @Input() match: any;
  @Input() teamId: any;
  @Input() matchId: any;
  @Input() ticketsAvilabilityForTeamHome: boolean;
  @Input() ticketsAvilabilityForTeamAway: boolean;

  ngOnInit() {
    this.initForm();
    if (this.matchId) {
      this.getMatchTIckets();
    }
  }

  private initForm() {
    this.teamSelectForm = this.fb.group({
      teamChoice: ['', Validators.required],
    });
  }

  next() {
    if (this.teamSelectForm.valid) {
      const selectedTeam = this.teamSelectForm.value.teamChoice || this.teamId;
      this.modalService.dismissAll(selectedTeam);
    }
  }

  dismiss() {
    this.modalService.dismissAll();
  }
  selectTeam(teamId: number) {
    this.teamSelectForm.patchValue({ teamChoice: teamId });
  }

  getMatchTIckets() {
    this.spinner.show();
    this.dataService
      .post(`${sportticketsApi}`, { matchId: this.matchId })
      .subscribe((res: IMatchTicket[]) => {
        if (res && Array.isArray(res)) {
          this.spinner.hide();
          this.tickets = res;
          if (this.tickets) {
            const teamHomeId = this.match?.teamHome?.id;
            const teamAwayId = this.match?.teamAway?.id;
            this.teamHomeTickets = this.tickets.filter(
              (ticket) => ticket.team.id === teamHomeId
            );
            this.teamAwayTickets = this.tickets.filter(
              (ticket) => ticket.team.id === teamAwayId
            );
            this.ticketsAvilabilityForTeamHome =
              this.teamHomeTickets.length > 0;
            this.ticketsAvilabilityForTeamAway =
              this.teamAwayTickets.length > 0;
          }
        }
      });
  }
}
