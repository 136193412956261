import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'toVat',
})
export class ToVATPipe implements PipeTransform {
  transform(number: number): any {
    return number * 1.15;
  }
}
