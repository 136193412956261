import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { catchError, Observable, switchMap, throwError } from 'rxjs';
import { HelpersService } from './helpers.service';
import { ACCESS_TOKEN, REFRESH_TOKEN } from '../constants/general.constants';
import { LanguageService } from './language.service';
import { AuthService } from 'src/app/auth/auth.service';

@Injectable({ providedIn: 'root' })
export class TokenInterceptor implements HttpInterceptor {
  constructor(
    private helpers: HelpersService,
    private languageService: LanguageService,
    private authService: AuthService
  ) {}
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const token = this.helpers.getItemFromLocalStorage(ACCESS_TOKEN);
    const refreshToken = this.helpers.getItemFromLocalStorage(REFRESH_TOKEN);
    const currentLang = this.languageService.getCurrnetLang() || 'en';
    const headers: any = {
      'Cache-Control':
        'no-cache, no-store, must-revalidate, post-check=0, pre-check=0',
      Pragma: 'no-cache',
    };
    headers['Access-Language'] = currentLang;
    headers['Accept-Language'] = currentLang;

    if (request.url.includes('/auth/refresh-token')) {
      headers.Authorization = `Bearer ${this.helpers.getItemFromLocalStorage(
        REFRESH_TOKEN
      )}`;
      request = request.clone({ setHeaders: headers });
      return next.handle(request);
    }

    if (token && this.helpers.isTokenExpired(token)) {
      return this.authService.refreshToken().pipe(
        switchMap((newToken: any) => {
          headers.Authorization = `Bearer ${this.helpers.getItemFromLocalStorage(
            ACCESS_TOKEN
          )}`;
          request = request.clone({ setHeaders: headers });
          return next.handle(request);
        }),
        catchError((error) => {
          if (request.url.includes('/auth/refresh-token')) {
            this.authService.logout();
          }
          return throwError(error);
        })
      );
    }
    if (this.helpers.getItemFromLocalStorage(ACCESS_TOKEN)) {
      headers.Authorization = `Bearer ${this.helpers.getItemFromLocalStorage(
        ACCESS_TOKEN
      )}`;
    }
    request = request.clone({ setHeaders: headers });

    return next.handle(request);
  }
}
