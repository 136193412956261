import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NgxPermissionsService, NgxRolesService } from 'ngx-permissions';
import {
  BehaviorSubject,
  catchError,
  filter,
  firstValueFrom,
  Observable,
  of,
  take,
  tap,
  throwError,
} from 'rxjs';
import {
  ACCESS_TOKEN,
  REFRESH_TOKEN,
  ROLES,
} from '../shared/constants/general.constants';
import {
  loginApi,
  logoutApi,
  refreshTokenApi,
} from '../shared/constants/api.constants';
import { ApplicationTypeEnum } from '../shared/enums/applicationType.enum';
import { IUser } from '../shared/models/IUser.model';
import { DataService } from '../shared/services/data.service';
import { HelpersService } from '../shared/services/helpers.service';
import { jwtDecode, JwtPayload } from 'jwt-decode';
import { NgxSpinnerService } from 'ngx-spinner';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  user = new BehaviorSubject<IUser>(null);
  user$ = this.user.asObservable();
  public isRefreshing = false;
  public refreshTokenSubject: BehaviorSubject<string | null> =
    new BehaviorSubject<string | null>(null);

  constructor(
    private helpers: HelpersService,
    private router: Router,
    private dataService: DataService,
    private spinner: NgxSpinnerService,
    private rolesService: NgxRolesService,
    private permissionsService: NgxPermissionsService
  ) {}

  setUser(user: IUser): void {
    this.user.next(user);
  }

  fetchUser(): void {
    const token = this.helpers.getItemFromLocalStorage(ACCESS_TOKEN);
    if(token) {
      let user;
      try {
        user = jwtDecode<any>(token);
        const permissions = [];
        const roles = user.user?.roles?.map((item: any) => item.key);
        this.permissionsService.addPermission(roles);
        if (roles) {
          permissions.push(...roles);
        }
        // user.user?.userPermission?.forEach((role: any) => {
        //   const keys = role?.actions?.map((action: any) => role.key + action.type);
        //   this.permissionsService.addPermission(keys);
        //   permissions.push(...keys);
        // });
        // this.helpers.setItemToLocalStorage(USER_PERMISSIONS, permissions);
        this.helpers.setItemToLocalStorage(ROLES, roles);
        this.setUser(user.user);

      } catch (error) {
        this.clearLocalStorage();
      }
    }
  }

  isAuthenticated(): boolean {
    return !!this.helpers.getItemFromLocalStorage(ACCESS_TOKEN);
  }

  signIn(mobile: any, password: string): Observable<any> {
    const body = {
      mobile: mobile.number,
      mobileRegionCode: mobile.countryCode,
      password: password,
    };
    return this.dataService.post(`${loginApi}`, body);
  }

  refreshToken(): Observable<string> {
    const refreshToken = this.helpers.getItemFromLocalStorage(REFRESH_TOKEN);
    if (!refreshToken) {
      console.error('No refresh token available');
      return throwError(() => new Error('No refresh token available'));
    }

    // If refresh is already in progress, return the subject as an observable
    if (this.isRefreshing) {
      return this.refreshTokenSubject.pipe(
        filter((token: any) => token !== null), // Wait until a new token is available
        take(1) // Complete after the first emission
      );
    }

    // Start the refresh process
    this.isRefreshing = true;
    this.refreshTokenSubject.next(null); // Reset the subject

    return this.dataService.post(`${refreshTokenApi}`, {}).pipe(
      tap((response) => {
        this.helpers.setItemToLocalStorage(ACCESS_TOKEN, response.access_token);
        this.helpers.setItemToLocalStorage(
          REFRESH_TOKEN,
          response.refresh_token
        );
        // Notify all queued requests with the new token
        this.refreshTokenSubject.next(response.access_token);
        this.isRefreshing = false;
      }),
      catchError((error) => {
        console.error('Refresh token failed', error);
        this.isRefreshing = false;
        this.refreshTokenSubject.next(null); // Notify failure
        this.logout();
        return throwError(error);
      })
    );
  }

  logout(): void {
    if (this.helpers.getItemFromLocalStorage(ACCESS_TOKEN)) {
      this.spinner.show();
      this.dataService.post(`${logoutApi}`, {}).subscribe(() => {
        this.spinner.hide();
        this.clearLocalStorage();
      });
    }
  }

  clearLocalStorage() {
    localStorage.clear();
    this.rolesService.flushRolesAndPermissions();
    this.setUser(null);
    this.router.navigateByUrl('/');
  }
}
