import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';

import { DataService } from '../../services/data.service';
import { teamsApi } from '../../constants/api.constants';
import { TranslateService } from '@ngx-translate/core';
import { HelpersService } from '../../services/helpers.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ChooseYourTeamDialogComponent } from 'src/app/landing/matches/match-details/components/choose-your-team-dialog/choose-your-team-dialog.component';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-book-title',
  templateUrl: './book-title.component.html',
  styleUrls: ['./book-title.component.scss'],
})
export class BookTitleComponent implements OnInit, OnChanges {
  @Input() booktitle;
  @Input() info;
  @Input() sportNote;
  @Input() teamId: any;
  @Input() match: any;
  @Output() teamIdChange = new EventEmitter<any>();
  team: any;
  isMobile = false;
  matchId: any;
  ticketsAvilabilityForTeamHome: boolean = true;
  ticketsAvilabilityForTeamAway: boolean = true;
  constructor(
    private dataService: DataService,
    public translate: TranslateService,
    public helpers: HelpersService,
    private modalService: NgbModal,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.isMobile = window.screen.width <= 768;
    // this.matchId = this.route.snapshot.paramMap.get('matchId');
  }

  ngOnInit(): void {}

  openChooseYourTeamDialog() {
    const modalRef = this.modalService.open(ChooseYourTeamDialogComponent);
    modalRef.componentInstance.match = this.match;
    modalRef.componentInstance.teamId = this.teamId;
    modalRef.componentInstance.matchId = this.match.id;
    modalRef.componentInstance.ticketsAvilabilityForTeamHome =
      this.ticketsAvilabilityForTeamHome;
    modalRef.componentInstance.ticketsAvilabilityForTeamAway =
      this.ticketsAvilabilityForTeamAway;
    modalRef.dismissed.subscribe((res) => {
      const updatedTeamId =
        res !== undefined &&
        res !== null &&
        res !== 0 &&
        res !== 1 &&
        res !== ''
          ? res
          : this.teamId;

      this.router.navigate(['book/sport'], {
        queryParams: {
          matchId: this.matchId,
          teamId: updatedTeamId,
        },
      });
      this.teamIdChange.emit(updatedTeamId);
    });
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes.match && changes.match.currentValue) {
      this.matchId = this.match.id;

      if (this.teamId) {
        this.getTeamDetails();
      }
    }
  }
  getTeamDetails() {
    if (this.teamId) {
      this.dataService.get(`${teamsApi}/${this.teamId}`).subscribe((res) => {
        this.team = res;
      });
    }
  }
}
