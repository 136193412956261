import { environment } from '../../../environments/environment';

const apiUrl = environment.apiRoot;
const authUrl = environment.authRoot;
const sportApiUrl = environment.sportApiRoot;

// Auth
export const userApi = `${authUrl}/user`;
export const loginApi = `${userApi}/login`;
export const signupApi = `${userApi}/signup`;
export const refreshTokenApi = `${authUrl}/auth/refresh-token`;
export const logoutApi = `${authUrl}/auth/logout`;
export const changePasswordApi = `${userApi}/profile/password`;
export const resetPasswordApi = `${userApi}/reset-password`;
export const activateApi = `${userApi}/activate`;
export const otpSendActivateApi = `${activateApi}/send-otp`;
export const otpVerifyActivateApi = `${activateApi}/verify-otp`;
export const otpVerifyResetApi = `${resetPasswordApi}/verify-otp`;
export const verifyEmailApi = `${activateApi}/email`;

export const usersApi = `${apiUrl}/users`;
export const adminApi = `${apiUrl}/admin`;
export const commercialRegistrationApi = `${apiUrl}/users/creators/commercial-registration`;
export const userProfileApi = `${usersApi}/profile`;
export const usersCreatorsApi = `${usersApi}/creators`;
export const signupCreatorApi = `${usersApi}/creators/create`;
export const creatorsApi = `${apiUrl}/creators`;
export const clientsApi = `${apiUrl}/partners`;
export const regionsApi = `${apiUrl}/regions`;
export const citiesApi = `${regionsApi}/cities`;
export const audienceApi = `${apiUrl}/common/audience`;
export const eventTypesApi = `${apiUrl}/common/eventType`;
export const contactUsApi = `${apiUrl}/contact-us/create`;
export const eventsApi = `${apiUrl}/events`;
export const eventsFeeApi = `${apiUrl}/eventsFee`;
export const settingsApi = `${creatorsApi}/events`;
export const eventsStatisticsApi = `${apiUrl}/events/statistic/dashboard`;
export const eventsDashboardApi = `${eventsApi}/dashboard`;
export const dashboardApi = `${apiUrl}/dashboard`;
export const publicEventApi = `${apiUrl}/public/event`;
export const publicEventsApi = `${apiUrl}/public/events`;
export const banners = `${apiUrl}/banners`;
export const ticketsApi = `${apiUrl}/events/tickets`;
export const publicPaymentsApi = `${apiUrl}/public/payments`;
export const validateCouponApi = `${apiUrl}/public/payments/promo-code/validate`;
export const attachmentsApi = `${apiUrl}/attachments`;
export const paymentsApi = `${apiUrl}/payments`;
export const statisticsRoleApi = `${userApi}/statistics/role`;
export const statisticsStatusApi = `${userApi}/statistics/status`;
export const usersManageApi = `${usersApi}/administration`;
export const exportSoldTicketsAdmin = `${adminApi}/tickets/download-excel`;
export const resendEmailActivationLink = `${adminApi}/users`;
export const bookCustomTicketForAdmin = `${adminApi}/payments/invoice`;
export const bookCustomTicketForEventUser = `${apiUrl}/sales-persons/payments/invoice`;
export const getAllTickets = `${adminApi}/payments/tickets/table`;
export const exportAllTickets = `${adminApi}/payments/tickets/table/download`;
export const ticketsStatastics = `${adminApi}/payments/tickets/statistics/status`;
export const resendTickets = `${adminApi}/payments/invoices`;
export const refundInvoice = `${adminApi}/payments/invoice`;
export const addClient = `${adminApi}/partners`;
export const exportSoldTicketsCreator = `${creatorsApi}/tickets/download-excel`;
export const viewInvoiceDetailsCreator = `${creatorsApi}/invoices`;
export const changeEventStatus = `${eventsApi}/dashboard`;
export const relatedEventsUser = `${eventsApi}/users/linked-events`;
export const soldTicketsForSalesPerson = `${paymentsApi}/events`;
export const ticketsTypesForSalesPerson = `${eventsApi}/users/event`;
export const invitationApi = `${eventsApi}/users/invite`;
export const checkInTicketApi = `${apiUrl}/scanners/events/orders`;
export const dailyInventoryReport = `${apiUrl}/scanners/events/daily-inventory`;
export const getPermission = `${apiUrl}/resources`;
export const getUserPermission = `${apiUrl}/permission/user`;
export const updateUserPermission = `${apiUrl}/permission/user`;
export const terminalsApi = `${apiUrl}/terminal`;
export const invitationsApi = `${apiUrl}/invitations`;
export const weaponsApi = `${apiUrl}/weapons`;
export const accessWeaponsApi = `${apiUrl}/access-weapons`;
export const accessWeaponsManagementApi = `${apiUrl}/access-weapons-management`;
export const tagsApi = `${apiUrl}/tags`;
export const buyerMailingTemplate = `${apiUrl}/buyer-mailing-template`;
export const badgeApi = `${apiUrl}/badge-template`;

// Sport apis
export const matchesApi = `${sportApiUrl}/matches`;
export const championsApi = `${sportApiUrl}/champions`;
export const teamsApi = `${sportApiUrl}/teams`;
export const stadiumsApi = `${sportApiUrl}/stadiums`;
export const commonCitiesApi = `${sportApiUrl}/common/cities`;
export const sportsMyTicketsApi = `${sportApiUrl}/orders/my-tickets`;
export const transferTicketApi = `${sportApiUrl}/transfer-order`;
export const sponsersApi = `${sportApiUrl}/sponsor`;
export const sportticketsApi = `${sportApiUrl}/tickets`;
export const sportInvoicesApi = `${sportApiUrl}/invoices`;
export const sportEarlySalesApi = `${sportApiUrl}/early-sales`;
