import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'customDatePipe',
})
export class customDatePipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  transform(value: any, args: any[]): any {
    const datePipe: DatePipe = new DatePipe('en');
    var date = new Date(value);
    var dateString = date.toLocaleDateString(args[1], {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    });
    return dateString;
  }
}
