<ng-container *ngIf="!teamId">
  <div
    title="{{ booktitle | translate }}"
    class="d-flex justify-content-between mobile-title"
  >
    <p class="book-now mb-0">{{ booktitle | translate }}</p>
    <div *ngIf="info && !isMobile" class="d-flex align-items-center">
      <img class="info-img" src="assets/images/events/info-circle.png" />
      <span class="info-text">{{ info | translate }}</span>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="teamId">
  <div
    title="{{ booktitle | translate }}"
    class="d-flex justify-content-between mobile-title mb-2"
  >
    <div class="">
      <p class="book-now mb-2">
        {{ "Available tickets for Club" | translate }}
        {{ helpers.getElementTranslatedName(team) }}
      </p>
      <p class="note mb-2">{{ "sportNote" | translate }}</p>
    </div>
    <div class="d-flex align-items-center mt-2" style="gap: 16px">
      <div class="col-3 padding-inline-0">
        <img
          class="info-sport-img"
          [attr.src]="
            team?.logo ? team?.logo : 'assets/images/placeholder-img.jpg'
          "
        />
      </div>
      <div
        class="col-7 padding-inline-0 d-flex align-items-center justify-content-between flex-wrap"
        style="gap: 8px"
      >
        <span class="info-text">
          {{ "Tickets Club" | translate }}
          <strong>{{ helpers.getElementTranslatedName(team) }}</strong>
        </span>
        <button
          class="btn btn-primary book-btn"
          (click)="openChooseYourTeamDialog()"
        >
          <span>
            {{ "Change Club" | translate }}
          </span>
        </button>
      </div>
    </div>
  </div>
</ng-container>
