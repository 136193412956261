import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';

@Pipe({ name: 'customCurrency' })
export class CustomCurrencyPipe implements PipeTransform {
  constructor(
    private translate: TranslateService,
    private sanitizer: DomSanitizer
  ) {}

  transform(
    value: number,
    currencyCode: string = 'SAR',
    displayType: 'text' | 'symbol' = 'text',
    color: string = '#E5263D',
    size: number = 16
  ): SafeHtml {
    const formattedValue = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: currencyCode,
      currencyDisplay: 'code',
    }).format(value);

    const amount = formattedValue.replace(currencyCode, '').trim();

    const translateCurrency = this.translate.instant('SR');

    const svgSymbol = `
      <svg class="d-inline" width="${size}" height="${size}" viewBox="0 0 240 240" fill="none" xmlns="http://www.w3.org/2000/svg" style="vertical-align: middle;">
        <path d="M133.663 56.3338C140.414 48.7553 144.563 45.3529 152.713 41.0513V167.247L133.663 171.184V56.3338Z" fill="${color}"/>
        <path d="M194.529 119.779C198.478 111.443 198.943 107.738 200 99.187L53.9804 130.894C50.5177 138.607 49.4031 142.918 48.8695 150.576L194.529 119.779Z" fill="${color}"/>
        <path d="M194.529 158.433C198.478 150.097 198.943 146.392 200 137.84L134.36 151.733C133.895 159.375 134.429 163.295 133.895 170.952L194.529 158.433Z" fill="${color}"/>
        <path d="M194.529 197.08C198.478 188.744 198.943 185.04 200 176.488L140.168 189.477C137.148 193.645 135.289 200.591 133.895 209.6L194.529 197.08Z" fill="${color}"/>
        <path d="M99.5134 183.919C105.321 176.741 111.361 167.711 115.543 160.301L45.1108 175.567C41.6481 183.28 40.5335 187.592 39.9999 195.249L99.5134 183.919Z" fill="${color}"/>
        <path d="M96.4934 45.6824C103.245 38.1039 107.394 34.7015 115.543 30.3999V160.764L96.4934 164.7V45.6824Z" fill="${color}"/>
      </svg>`;

    let result;
    if (displayType === 'text') {
      result =
        this.translate.currentLang === 'ar'
          ? `${amount} <span class="text-success">${translateCurrency}</span>`
          : `<span class="text-success">${translateCurrency}</span> ${amount}`;
    } else {
      result =
        this.translate.currentLang === 'ar'
          ? `${amount} ${svgSymbol}`
          : `${svgSymbol} ${amount}`;
    }

    return this.sanitizer.bypassSecurityTrustHtml(result);
  }
}
