import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { transferTicketApi } from 'src/app/shared/constants/api.constants';
import { Toast } from 'src/app/shared/enums/toast';
import { DataService } from 'src/app/shared/services/data.service';
import { ToastService } from 'src/app/shared/services/toast-service';

@Component({
  selector: 'app-transfer-tickets',
  templateUrl: './transfer-tickets.component.html',
  styleUrls: ['./transfer-tickets.component.scss'],
})
export class TransferTicketsComponent implements OnInit {
  form: FormGroup;
  @Input() apiCall: any;
  @Input() invoiceId: any;
  @Input() orderId: any;
  @Input() selectedTickets: any;
  errorMessage: any;
  successMessage: any;
  @Output() transfer: EventEmitter<any> = new EventEmitter();
  constructor(
    private fb: FormBuilder,
    private modalService: NgbModal,
    private spinner: NgxSpinnerService,
    private dataService: DataService,
    private toast: ToastService,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.initForm();
  }

  private initForm(): void {
    this.form = this.fb.group({
      mobile: [null, [Validators.required]],
    });
  }

  submit() {
    switch (this.apiCall) {
      case 'invoice':
        this.transferInvoiceCall();
        break;
      case 'ticket':
        this.transferTicketCall();
        break;
      case 'ticketId':
        this.transferBulkbyId();
        break;
      case 'ticketNumber':
        this.transferBulkbyId();
        break;
      default:
        this.transferDisabledTicketCall();
    }
  }

  dismiss() {
    this.modalService.dismissAll();
  }

  transferDisabledTicketCall() {
    this.spinner.show();
    const body = {
      ticketNumber: this.orderId,
      mobile: this.form.value.mobile.number,
      mobileRegionCode: this.form.value.mobile.countryCode,
    };
    this.dataService
      .post(`${transferTicketApi}/for-order`, body)
      .subscribe((res) => {
        if (res?.status !== 'success') {
          this.spinner.hide();
          this.toast.show('Error', res.error, {
            classname: Toast.error,
          });
        } else {
          this.spinner.hide();

          this.transfer.emit();
          this.toast.show(
            'Success',
            this.translate.instant('Sport.TicketTransferred'),
            {
              classname: Toast.success,
            }
          );
        }
      });
  }

  transferInvoiceCall() {
    this.spinner.show();

    const body = {
      invoiceId: this.invoiceId,
      mobile: this.form.value.mobile.number,
      mobileRegionCode: this.form.value.mobile.countryCode,
    };
    this.dataService
      .post(`${transferTicketApi}/for-invoice`, body)
      .subscribe((res) => {
        if (res?.status !== 'success') {
          this.spinner.hide();
          this.toast.show('Error', res.error, {
            classname: Toast.error,
          });
        } else {
          this.spinner.hide();

          this.transfer.emit();
          this.toast.show(
            'Success',
            this.translate.instant('Sport.InvoiceTransferred'),
            {
              classname: Toast.success,
            }
          );
        }
      });
  }

  transferTicketCall() {
    this.spinner.show();
    const body = {
      orderId: this.orderId,
      mobile: this.form.value.mobile.number,
      mobileRegionCode: this.form.value.mobile.countryCode,
    };
    this.dataService
      .post(`${transferTicketApi}/for-order`, body)
      .subscribe((res) => {
        if (res?.status !== 'success') {
          this.spinner.hide();
          this.toast.show('Error', res.error, {
            classname: Toast.error,
          });
        } else {
          this.spinner.hide();

          this.transfer.emit();
          this.toast.show(
            'Success',
            this.translate.instant('Sport.TicketTransferred'),
            {
              classname: Toast.success,
            }
          );
        }
      });
  }

  transferBulkbyId() {
    this.spinner.show();
    const body = this.selectedTickets.map((ticket) => {
      return {
        orderId: ticket.id,
        mobile: this.form.value.mobile.number,
        mobileRegionCode: this.form.value.mobile.countryCode,
      };
    });
    this.dataService
      .post(`${transferTicketApi}/transfer-bulk`, body)
      .subscribe((res) => {
        if (res?.status !== 'success') {
          this.spinner.hide();
          this.toast.show('Error', res.error, {
            classname: Toast.error,
          });
        } else {
          this.spinner.hide();

          this.transfer.emit();
          this.toast.show(
            'Success',
            this.translate.instant('Sport.TicketTransferred'),
            {
              classname: Toast.success,
            }
          );
        }
      });
  }

  transferBulkbyNumber() {
    this.spinner.show();
    const body = this.selectedTickets.map((ticket) => {
      return {
        ticketNumber: ticket.ticketNumber,
        mobile: this.form.value.mobile.number,
        mobileRegionCode: this.form.value.mobile.countryCode,
      };
    });
    this.dataService
      .post(`${transferTicketApi}/transfer-bulk`, body)
      .subscribe((res) => {
        if (res?.status !== 'success') {
          this.spinner.hide();
          this.toast.show('Error', res.error, {
            classname: Toast.error,
          });
        } else {
          this.spinner.hide();

          this.transfer.emit();
          this.toast.show(
            'Success',
            this.translate.instant('Sport.TicketTransferred'),
            {
              classname: Toast.success,
            }
          );
        }
      });
  }
}
