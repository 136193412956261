import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ITableRow } from '../../models/ITable.model';
import { Router } from '@angular/router';
import { getEventStatusKeyByValue } from '../../enums/eventStatus';

@Component({
  selector: 'app-evento-table',
  templateUrl: './evento-table.component.html',
  styleUrls: ['./evento-table.component.scss'],
  animations: [
    trigger('smoothCollapse', [
      state(
        'initial',
        style({
          height: '0',
          overflow: 'hidden',
          opacity: '0',
          visibility: 'hidden',
        })
      ),
      state(
        'finale',
        style({
          height: '*',
          overflow: 'hidden',
          opacity: '1',
          visibility: 'visible',
        })
      ),
      transition('initial <=> finale', animate('250ms ease-in-out')),
    ]),
    trigger('rotatedState', [
      state('default', style({ transform: 'rotate(0)' })),
      state('rotated', style({ transform: 'rotate(180deg)' })),
      transition('default <=> rotated', animate('250ms')),
    ]),
  ],
})
export class EventoTableComponent implements OnInit {
  @Input() headers: string[] = [];
  @Input() columns: string[] = [];
  @Input() data: ITableRow[] = [];
  protected readonly Number = Number;
  openedAccordions: { [key: number]: boolean } = {};

  @Input() translatedValue: string;
  @Output() buttonValueChange = new EventEmitter<{
    status: string;
    id: string;
  }>();

  constructor(private router: Router) {}

  toggleAccordion(index: number) {
    this.openedAccordions[index] = !this.openedAccordions[index];
  }

  onButtonValueChange(statusValue: string, id: string) {
    const statusKey = getEventStatusKeyByValue(statusValue);
    this.buttonValueChange.emit({ status: statusKey, id });
  }

  ngOnInit() {
    this.data.forEach((_, index) => {
      this.openedAccordions[index] = false;
    });
  }

  onClick(link: string) {
    this.router.navigate([link]);
  }
}
