import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-nodata',
  templateUrl: './nodata.component.html',
  styleUrls: ['./nodata.component.scss'],
})
export class NodataComponent implements OnInit {
  @Input() title: string;
  @Input() desc = '';
  @Input() img: string;
  @Input() link;
  constructor() {}

  ngOnInit(): void {}
}
