import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timePipe',
})
export class TimePipe implements PipeTransform {
  constructor() {}

  transform(value: any, args?: any): any {
    const time = value.split(':');
    let hour = time[0];
    let min = time[1];
    let part = hour > 12 ? 'pm' : 'am';
    if (args[0] == 'ar') {
      part = hour > 12 ? 'مساء' : 'صباحا'
    }
    if (parseInt(hour) == 0) hour = 12;
    min = (min + '').length == 1 ? `0${min}` : min;
    hour = hour > 12 ? hour - 12 : hour;
    hour = (hour + '').length == 1 ? `0${hour}` : hour;
    return `${hour}:${min} ${part}`;
    // return time[0] + ':' + time[1]
  }
}
