import { Injectable, Inject, EventEmitter } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { DOCUMENT } from '@angular/common';
import { Title } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  constructor(
    private translateService: TranslateService,
    private titleService: Title,
    @Inject(DOCUMENT) private document: Document
  ) {}

  getCurrnetLang(): string {
    const lang = this.translateService.currentLang
      ? this.translateService.currentLang
      : localStorage.getItem('DEFAULT_LANGUAGE')
      ? localStorage.getItem('DEFAULT_LANGUAGE')
      : environment.defaultLanguage;
    return lang || 'ar';
  }

  loadDefaultLangsAndStyles(): void {
    this.setLangsAndStyles();
  }

  isDefaultLang(): boolean {
    return this.translateService.currentLang === 'ar' ? true : false;
  }

  switchLanguages(): void {
    const lang = this.translateService.currentLang === 'en' ? 'ar' : 'en';
    this.setLangsAndStyles(lang);
  }

  onLangChange(): EventEmitter<LangChangeEvent> {
    return this.translateService.onLangChange;
  }

  private setLangsAndStyles(lang: string = this.getCurrnetLang()): void {
    localStorage.setItem('DEFAULT_LANGUAGE', this.removeStringQuotes(lang));
    this.translateService.setDefaultLang(this.removeStringQuotes(lang));
    this.setStylesDirections(this.removeStringQuotes(lang));
  }

  removeStringQuotes(str: string): string {
    return str.replace(/['"]+/g, '');
  }

  private setStylesDirections(lang: string): void {
    const htmlTag = this.document.getElementsByTagName('html');
    if (lang === 'ar') {
      this.document.body.setAttribute('dir', 'rtl');
      htmlTag[0].classList.remove('ltr');
      htmlTag[0].classList.add('rtl');
      htmlTag[0].setAttribute('lang', lang);
      this.loadStyle('rtl-style.css');
      //   location.pathname = location.pathname.replace(/.*?\/en\/.*?/gi, '/ar/');
      this.titleService.setTitle(environment.arTitle);
    } else {
      this.document.body.setAttribute('dir', 'ltr');
      htmlTag[0].classList.remove('rtl');
      htmlTag[0].classList.add('ltr');
      htmlTag[0].setAttribute('lang', 'ar');
      this.loadStyle('ltr-style.css');
      //   location.pathname = location.pathname.replace(/.*?\/ar\/.*?/gi, '/en/');
      this.titleService.setTitle(environment.enTitle);
    }
    this.translateService.setDefaultLang(lang);
    this.translateService.use(lang);
    // this.language.setLang(lang);
    // this.location.go(location.pathname);
  }

  private loadStyle(styleName: string): void {
    const head = this.document.getElementsByTagName('head')[0];

    const themeLink = this.document.getElementById(
      'client-theme'
    ) as HTMLLinkElement;
    if (themeLink) {
      themeLink.href = styleName;
    } else {
      const style = this.document.createElement('link');
      style.id = 'client-theme';
      style.rel = 'stylesheet preload';
      style.as = 'style';

      style.href = `${styleName}`;

      head.appendChild(style);
    }
  }
}
