<div class="popup">
  <div class="mt-0">
    <div class="d-flex justify-content-between">
      <p class="popup__title">
        {{ "Please choose Your Team" | translate }}
      </p>
      <i
        (click)="dismiss()"
        class="fa fa-times"
        aria-hidden="true"
        style="cursor: pointer; color: #555770; font-size: 18px"
      ></i>
    </div>

    <form [formGroup]="teamSelectForm">
      <div class="form-group width-adjust mt-3">
        <!-- Team Home -->
        <div
          class="col-12 customPadding"
          style="padding: 8px 16px; cursor: pointer"
          [ngClass]="{
            disabled_div: !ticketsAvilabilityForTeamHome,
            active_div: teamSelectForm.value.teamChoice === match.teamHome.id
          }"
          (click)="selectTeam(match.teamHome.id)"
        >
          <div class="line">
            <label class="custom-control">
              <input
                type="radio"
                formControlName="teamChoice"
                [value]="match.teamHome.id"
                class="custom-control-input"
              />
              <span class="checkmark"></span>
              <span class="custom-label">
                {{ helpers.getElementTranslatedName(match.teamHome) }}
              </span>
            </label>

            <div
              class="d-flex justify-content-center custom-position"
              *ngIf="!ticketsAvilabilityForTeamHome"
            >
              <span class="disabled_label">
                {{ "Tickets Sold Out" | translate }}
              </span>
            </div>
            <img
              [src]="match.teamHome.logo"
              loading="lazy"
              alt=""
              width="48"
              height="48"
            />
          </div>
        </div>
        <!-- Team Away -->
        <div
          class="col-12 customPadding mt-3"
          style="padding: 8px 16px; cursor: pointer"
          [ngClass]="{
            disabled_div: !ticketsAvilabilityForTeamAway,
            active_div: teamSelectForm.value.teamChoice === match.teamAway.id
          }"
          (click)="selectTeam(match.teamAway.id)"
        >
          <div class="line">
            <label class="custom-control">
              <input
                type="radio"
                formControlName="teamChoice"
                [value]="match.teamAway.id"
                class="custom-control-input"
              />
              <span class="checkmark"></span>
              <span class="custom-label">
                {{ helpers.getElementTranslatedName(match.teamAway) }}
              </span>
            </label>
            <div
              class="d-flex justify-content-center custom-position"
              *ngIf="!ticketsAvilabilityForTeamAway"
            >
              <span class="disabled_label">
                {{ "Tickets Sold Out" | translate }}
              </span>
            </div>
            <img
              [src]="match.teamAway.logo"
              loading="lazy"
              alt=""
              width="48"
              height="48"
            />
          </div>
        </div>
        <div
          class="text-center col-12"
          style="margin: auto; margin-top: 32px; padding: 0"
        >
          <button
            class="btn btn-primary book-btn"
            [disabled]="teamSelectForm.invalid"
            (click)="next()"
          >
            <span>
              {{ "next" | translate }}
            </span>
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
