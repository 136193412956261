<div class="container p-4">
  <h4>{{ "Sport.TransferTicket" | translate }}</h4>
  <span> {{ "Sport.TransferDesc" | translate }} </span>
  <form [formGroup]="form" class="form mt-4">
    <div class="form-group">
      <app-evento-input
        [eventoFormControl]="form.get('mobile')"
        type="mobile"
        requiredMessage="{{ 'Required Field' | translate }}"
        validatePhoneNumberMessage="{{ 'Valid Mobile Number' | translate }}"
        label="{{ 'Mobile number' | translate }}"
      ></app-evento-input>
    </div>
    <div class="d-flex" style="justify-content: end; gap: 10px">
      <button (click)="dismiss()" class="btn btn-primary set-prediction">
        <ng-container>{{ "Cancel" | translate }}</ng-container>
      </button>
      <button
        type="button"
        class="btn reserveTicketWizard-nextBtn"
        [ngStyle]="
          !form.valid ? null : { background: '#3dbeb4', color: 'white' }
        "
        [ngClass]="{
          'disabled-btn': !form.valid
        }"
        [disabled]="!form.valid"
        (click)="submit()"
      >
        {{ "Send" | translate }}
      </button>
    </div>
  </form>
</div>
