import {
  HttpClient,
  HttpClientModule,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule, Type } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbTimeAdapter } from '@ng-bootstrap/ng-bootstrap';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import {
  RecaptchaModule,
  ReCaptchaV3Service,
  RECAPTCHA_V3_SITE_KEY,
} from 'ng-recaptcha';
import { SharedModule } from 'src/app/shared/shared.module';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ToastsContainerComponent } from './shared/components/toasts-container/toasts-container.component';
import { ErrorInterceptor } from './shared/services/error.interceptor';
import { NoCacheHeadersInterceptor } from './shared/services/noCache.interceptor';
import { NgbTimeStringAdapter } from './shared/services/timepicker-adapter.service';
import { TokenInterceptor } from './shared/services/token.interceptor';
import { AgmCoreModule } from '@agm/core';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { FlatpickrModule } from 'angularx-flatpickr';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { NgxPermissionsModule } from 'ngx-permissions';
export function TranslationLoaderFactory(
  http: HttpClient
): TranslateHttpLoader {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

const components: any[] | Type<any> = [AppComponent, ToastsContainerComponent];

@NgModule({
  declarations: [components],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    SharedModule,
    HttpClientModule,
    RecaptchaModule,
    BrowserAnimationsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: TranslationLoaderFactory,
        deps: [HttpClient],
      },
    }),
    AgmCoreModule.forRoot({
      // apiKey: 'AIzaSyCcSYMEROYS9zcvC5wCnj5nvgSypJXnJ2Q',
      apiKey: 'AIzaSyDVMI9wsdPyTd0oK9m_9UBwl_SqidhW0RE',
      libraries: ['places'],
    }),
    FlatpickrModule.forRoot(),
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
    NgxPermissionsModule.forRoot(),
  ],
  providers: [
    ReCaptchaV3Service,
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: '6LfVbQArAAAAAIKLDhD_Yk674x2VcpW4VwzTKkOo',
    },
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: NoCacheHeadersInterceptor,
      multi: true,
    },
    { provide: NgbTimeAdapter, useClass: NgbTimeStringAdapter },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
