import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'localizedDate',
})
export class LocalizedDatePipe implements PipeTransform {
  constructor(private translate: TranslateService) {}

  transform(value: string, format: string, locale: string): string {
    const datePipe: any = new DatePipe(locale);
    return datePipe.transform(new Date(value), format);
  }
}
